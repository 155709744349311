var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "content" },
    [
      _c("div", { staticClass: "top" }, [
        _c("div", [
          _c(
            "div",
            { staticClass: "item-btn" },
            [
              _c("a-input", {
                staticStyle: { width: "150px", margin: "5px" },
                attrs: {
                  allowClear: "",
                  size: "small",
                  placeholder: "全网搜索"
                },
                model: {
                  value: _vm.searchAllStr,
                  callback: function($$v) {
                    _vm.searchAllStr = $$v
                  },
                  expression: "searchAllStr"
                }
              }),
              _c(
                "a-button",
                {
                  staticStyle: { "margin-left": "10px" },
                  attrs: { type: "primary", size: "small" },
                  on: { click: _vm.searchAll }
                },
                [_vm._v("全网搜索")]
              )
            ],
            1
          )
        ]),
        _c(
          "div",
          { staticStyle: { display: "flex" } },
          [
            _c(
              "a-upload",
              {
                key: _vm.imgKey,
                attrs: {
                  name: "file",
                  multiple: true,
                  action: _vm.ip + "/file/upload",
                  showUploadList: false
                },
                on: { change: _vm.handleChanges }
              },
              [
                _c(
                  "a-button",
                  {
                    staticStyle: { "margin-right": "20px" },
                    attrs: { type: "primary" }
                  },
                  [_vm._v("上传PCGS图片录入")]
                )
              ],
              1
            ),
            _c(
              "a-button",
              {
                attrs: { type: "primary" },
                on: {
                  click: function($event) {
                    return _vm.openModal({ custom: 1 })
                  }
                }
              },
              [_vm._v("手动录入")]
            )
          ],
          1
        )
      ]),
      _c("div", { staticClass: "search-attr" }, [
        _c(
          "div",
          [
            _c("a-input", {
              staticStyle: { width: "150px", margin: "5px" },
              attrs: {
                allowClear: "",
                size: "small",
                placeholder: "银元版别名称"
              },
              model: {
                value: _vm.params.keyword,
                callback: function($$v) {
                  _vm.$set(_vm.params, "keyword", $$v)
                },
                expression: "params.keyword"
              }
            }),
            _c(
              "a-select",
              {
                staticStyle: { width: "150px", margin: "5px" },
                attrs: { allowClear: "", size: "small", placeholder: "数据源" },
                model: {
                  value: _vm.params.type,
                  callback: function($$v) {
                    _vm.$set(_vm.params, "type", $$v)
                  },
                  expression: "params.type"
                }
              },
              [
                _c("a-select-option", { attrs: { value: 1 } }, [_vm._v("HX")]),
                _c("a-select-option", { attrs: { value: 3 } }, [_vm._v("WPT")]),
                _c("a-select-option", { attrs: { value: 4 } }, [_vm._v("XZ")]),
                _c("a-select-option", { attrs: { value: 10 } }, [
                  _vm._v("SBP")
                ]),
                _c("a-select-option", { attrs: { value: 2 } }, [_vm._v("QBTT")])
              ],
              1
            ),
            _c(
              "a-select",
              {
                staticStyle: { width: "150px", margin: "5px" },
                attrs: {
                  allowClear: "",
                  size: "small",
                  placeholder: "评级公司"
                },
                model: {
                  value: _vm.params.companyType,
                  callback: function($$v) {
                    _vm.$set(_vm.params, "companyType", $$v)
                  },
                  expression: "params.companyType"
                }
              },
              [
                _c("a-select-option", { attrs: { value: 1 } }, [
                  _vm._v("PCGS")
                ]),
                _c("a-select-option", { attrs: { value: 2 } }, [
                  _vm._v("公博")
                ]),
                _c("a-select-option", { attrs: { value: 0 } }, [_vm._v("无")])
              ],
              1
            ),
            _c(
              "a-select",
              {
                staticStyle: { width: "150px", margin: "5px" },
                attrs: {
                  allowClear: "",
                  size: "small",
                  placeholder: "是否有封面图"
                },
                model: {
                  value: _vm.params.coverUrlState,
                  callback: function($$v) {
                    _vm.$set(_vm.params, "coverUrlState", $$v)
                  },
                  expression: "params.coverUrlState"
                }
              },
              [
                _c("a-select-option", { attrs: { value: 1 } }, [_vm._v("有")]),
                _c("a-select-option", { attrs: { value: 0 } }, [_vm._v("无")])
              ],
              1
            ),
            _c(
              "a-select",
              {
                staticStyle: { margin: "5px", "min-width": "150px" },
                attrs: {
                  size: "small",
                  mode: "multiple",
                  placeholder: "选择评分(可多选)"
                },
                model: {
                  value: _vm.params.scoreKeyword,
                  callback: function($$v) {
                    _vm.$set(_vm.params, "scoreKeyword", $$v)
                  },
                  expression: "params.scoreKeyword"
                }
              },
              _vm._l(_vm.ranks, function(item) {
                return _c(
                  "a-select-option",
                  { key: item, attrs: { value: item } },
                  [_vm._v(_vm._s(item))]
                )
              }),
              1
            ),
            _c("a-range-picker", {
              staticStyle: { margin: "5px" },
              attrs: { placeholder: ["开始时间", "结束时间"], size: "small" },
              on: { change: _vm.onChange }
            }),
            _c(
              "a-button",
              {
                staticStyle: { "margin-left": "10px" },
                attrs: { size: "small" },
                on: { click: _vm.search }
              },
              [_vm._v("搜索")]
            )
          ],
          1
        )
      ]),
      _c(
        "div",
        {
          staticClass: "table-content",
          staticStyle: { "padding-top": "20px" }
        },
        [
          _c("a-table", {
            attrs: {
              pagination: _vm.pagination,
              loading: _vm.loading,
              columns: _vm.columns,
              "data-source": _vm.data,
              rowKey: function(record) {
                return record.id
              }
            },
            on: { change: _vm.changePage },
            scopedSlots: _vm._u([
              {
                key: "Images",
                fn: function(row) {
                  return _c(
                    "div",
                    {
                      staticStyle: {
                        width: "100px",
                        height: "100px",
                        display: "flex",
                        "justify-content": "center",
                        "align-items": "center"
                      }
                    },
                    [
                      _c("img", {
                        staticStyle: {
                          "max-width": "100px",
                          "max-height": "100px"
                        },
                        attrs: {
                          src:
                            row.images != null
                              ? row.images[0]
                              : "http://image.douquan.com/static/product-default.png",
                          alt: ""
                        },
                        on: {
                          click: function($event) {
                            return _vm.previewImg(row)
                          }
                        }
                      })
                    ]
                  )
                }
              },
              {
                key: "setup",
                fn: function(row) {
                  return _c(
                    "div",
                    {},
                    [
                      _c(
                        "a-button",
                        {
                          staticStyle: { "margin-right": "5px" },
                          attrs: { type: "primary", size: "small" },
                          on: {
                            click: function($event) {
                              return _vm.editDollat(row)
                            }
                          }
                        },
                        [_vm._v("编辑")]
                      ),
                      _c(
                        "a-button",
                        {
                          staticStyle: { "margin-right": "5px" },
                          attrs: { type: "primary", size: "small" },
                          on: {
                            click: function($event) {
                              return _vm.updateImage(row)
                            }
                          }
                        },
                        [_vm._v("更新封面")]
                      ),
                      _c(
                        "a-button",
                        {
                          staticStyle: { "margin-right": "5px" },
                          attrs: { type: "primary", size: "small" },
                          on: {
                            click: function($event) {
                              return _vm.openModal(row)
                            }
                          }
                        },
                        [_vm._v("录入")]
                      ),
                      _c(
                        "a-popconfirm",
                        {
                          attrs: {
                            title: "Are you sure delete this item?",
                            "ok-text": "Yes",
                            "cancel-text": "No"
                          },
                          on: {
                            confirm: function($event) {
                              return _vm.remove(row.id)
                            }
                          }
                        },
                        [
                          _c(
                            "a-button",
                            {
                              staticStyle: { "margin-left": "5px" },
                              attrs: { size: "small", type: "danger" }
                            },
                            [_vm._v("删除")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                }
              }
            ])
          })
        ],
        1
      ),
      _c(
        "a-modal",
        {
          directives: [{ name: "drag-modal", rawName: "v-drag-modal" }],
          attrs: {
            width: 1000,
            title: "录入",
            "ok-text": "确认",
            "cancel-text": "取消"
          },
          on: { cancel: _vm.resetForms, ok: _vm.hideModal },
          model: {
            value: _vm.visible,
            callback: function($$v) {
              _vm.visible = $$v
            },
            expression: "visible"
          }
        },
        [
          _c(
            "a-form-model",
            _vm._b(
              {
                ref: "ruleForm",
                attrs: { model: _vm.goods, rules: _vm.rules }
              },
              "a-form-model",
              _vm.layout,
              false
            ),
            [
              _c(
                "a-form-model-item",
                { attrs: { label: "商品名称" } },
                [
                  _c("a-input", {
                    attrs: { placeholder: "商品名称" },
                    model: {
                      value: _vm.goods.title,
                      callback: function($$v) {
                        _vm.$set(_vm.goods, "title", $$v)
                      },
                      expression: "goods.title"
                    }
                  })
                ],
                1
              ),
              _c(
                "a-form-model-item",
                { attrs: { label: "评级公司", prop: "goodsType" } },
                [
                  _c(
                    "a-radio-group",
                    {
                      attrs: { name: "radioGroup" },
                      on: { change: _vm.setRadioGroup },
                      model: {
                        value: _vm.goods.goodsType,
                        callback: function($$v) {
                          _vm.$set(_vm.goods, "goodsType", $$v)
                        },
                        expression: "goods.goodsType"
                      }
                    },
                    [
                      _c("a-radio", { attrs: { value: 1 } }, [_vm._v("PCGS")]),
                      _c("a-radio", { attrs: { value: 2 } }, [_vm._v("公博")])
                    ],
                    1
                  ),
                  _vm.goods.goodsType == 1
                    ? _c("div", [
                        _c(
                          "div",
                          {
                            staticStyle: {
                              display: "flex",
                              "align-items": "center"
                            }
                          },
                          [
                            _c("span", [_vm._v("编码: ")]),
                            _c("a-input", {
                              staticStyle: { flex: "1" },
                              attrs: { size: "small" },
                              on: { blur: _vm.changeCode },
                              model: {
                                value: _vm.goods.pcgsCode,
                                callback: function($$v) {
                                  _vm.$set(_vm.goods, "pcgsCode", $$v)
                                },
                                expression: "goods.pcgsCode"
                              }
                            })
                          ],
                          1
                        ),
                        _c(
                          "div",
                          [
                            _vm.showCheckRadio
                              ? _c(
                                  "a-radio-group",
                                  {
                                    attrs: { name: "radioGroup" },
                                    on: { change: _vm.changeRadio }
                                  },
                                  _vm._l(_vm.checkVersionsList, function(
                                    items
                                  ) {
                                    return _c(
                                      "a-radio",
                                      {
                                        key: items.kindVersionId,
                                        attrs: { value: items.kindVersionId }
                                      },
                                      [_vm._v(_vm._s(items.kindVersionName))]
                                    )
                                  }),
                                  1
                                )
                              : _vm._e()
                          ],
                          1
                        ),
                        _vm.goods.versionName
                          ? _c("span", [
                              _vm._v(
                                "版别名称:" + _vm._s(_vm.goods.versionName)
                              )
                            ])
                          : _vm._e(),
                        _c(
                          "div",
                          {
                            staticStyle: {
                              display: "flex",
                              "align-items": "center"
                            }
                          },
                          [
                            _c("span", [_vm._v("编号: ")]),
                            _c("a-input", {
                              staticStyle: { flex: "1" },
                              attrs: { size: "small" },
                              model: {
                                value: _vm.goods.pcgsNumber,
                                callback: function($$v) {
                                  _vm.$set(_vm.goods, "pcgsNumber", $$v)
                                },
                                expression: "goods.pcgsNumber"
                              }
                            })
                          ],
                          1
                        )
                      ])
                    : _vm._e(),
                  _vm.goods.goodsType == 2
                    ? _c(
                        "div",
                        [
                          _c(
                            "a-select",
                            {
                              attrs: {
                                size: "small",
                                placeholder: "请选择银元一级大币种"
                              },
                              on: { change: _vm.changeKindIdForverSions },
                              model: {
                                value: _vm.goods.kindId,
                                callback: function($$v) {
                                  _vm.$set(_vm.goods, "kindId", $$v)
                                },
                                expression: "goods.kindId"
                              }
                            },
                            _vm._l(_vm.bigList, function(items) {
                              return _c(
                                "a-select-option",
                                {
                                  key: items.kindId,
                                  attrs: { value: items.kindId }
                                },
                                [_vm._v(" " + _vm._s(items.kindName) + " ")]
                              )
                            }),
                            1
                          ),
                          _c(
                            "a-select",
                            {
                              attrs: {
                                allowClear: "",
                                showSearch: "",
                                "filter-option": _vm.untils.filterOption,
                                size: "small",
                                placeholder: "请选择银元二级版别"
                              },
                              on: {
                                select: function($event) {
                                  return _vm.getInfoByCode($event, 2)
                                },
                                search: _vm.getVersitionList
                              },
                              model: {
                                value: _vm.goods.versionId,
                                callback: function($$v) {
                                  _vm.$set(_vm.goods, "versionId", $$v)
                                },
                                expression: "goods.versionId"
                              }
                            },
                            _vm._l(_vm.smallList, function(items) {
                              return _c(
                                "a-select-option",
                                {
                                  key: items.versionId,
                                  attrs: { value: items.versionId }
                                },
                                [_vm._v(" " + _vm._s(items.versionName) + " ")]
                              )
                            }),
                            1
                          ),
                          _c("a-input", {
                            attrs: {
                              size: "small",
                              placeholder: "请输入公博盒子证书编号"
                            },
                            on: {
                              change: function($event) {
                                return _vm.getInfoByCode($event, 2)
                              }
                            },
                            model: {
                              value: _vm.goods.pcgsNumber,
                              callback: function($$v) {
                                _vm.$set(_vm.goods, "pcgsNumber", $$v)
                              },
                              expression: "goods.pcgsNumber"
                            }
                          }),
                          _vm.infoByCode.dealState.inputStatus == 1
                            ? _c("span", [
                                _vm._v("提示: "),
                                _c("label", { staticStyle: { color: "red" } }, [
                                  _vm._v(
                                    _vm._s(_vm.infoByCode.dealState.message)
                                  )
                                ])
                              ])
                            : _vm._e()
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              ),
              _c(
                "a-form-model-item",
                { attrs: { label: "图片" } },
                [
                  _c(
                    "a-upload",
                    {
                      key: _vm.imgKey,
                      attrs: {
                        action: _vm.ip + "/file/upload",
                        "list-type": "picture-card",
                        "file-list": _vm.fileList
                      },
                      on: {
                        preview: function($event) {
                          return _vm.handlePreview($event, 2)
                        },
                        change: _vm.handleChange
                      }
                    },
                    [
                      _c(
                        "div",
                        [
                          _c("a-icon", { attrs: { type: "plus" } }),
                          _c("div", { staticClass: "ant-upload-text" }, [
                            _vm._v("上传图片")
                          ])
                        ],
                        1
                      )
                    ]
                  ),
                  _c(
                    "div",
                    [
                      _c(
                        "a-button",
                        {
                          on: {
                            click: function($event) {
                              return _vm.previewImgs(_vm.fileList)
                            }
                          }
                        },
                        [_vm._v("预览图片")]
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "a-form-item",
                { attrs: { label: "封面图" } },
                [
                  _c(
                    "a-upload",
                    {
                      key: _vm.imgKey,
                      attrs: {
                        action: _vm.ip + "/file/upload",
                        "list-type": "picture-card",
                        "file-list": _vm.coverList
                      },
                      on: {
                        preview: _vm.handlePreview,
                        change: _vm.handleChangeCoverList
                      }
                    },
                    [
                      _c(
                        "div",
                        [
                          _c("a-icon", { attrs: { type: "plus" } }),
                          _c("div", { staticClass: "ant-upload-text" }, [
                            _vm._v("上传图片")
                          ])
                        ],
                        1
                      )
                    ]
                  )
                ],
                1
              ),
              _c(
                "a-form-model-item",
                { attrs: { label: "白币", prop: "whiteCoinStatus" } },
                [
                  _c(
                    "a-select",
                    {
                      attrs: { size: "small", placeholder: "请选择" },
                      model: {
                        value: _vm.goods.whiteCoinStatus,
                        callback: function($$v) {
                          _vm.$set(_vm.goods, "whiteCoinStatus", $$v)
                        },
                        expression: "goods.whiteCoinStatus"
                      }
                    },
                    [
                      _c("a-select-option", { attrs: { value: 1 } }, [
                        _vm._v("是")
                      ]),
                      _c("a-select-option", { attrs: { value: 0 } }, [
                        _vm._v("否")
                      ])
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "a-form-model-item",
                { attrs: { label: "评级分数", prop: "scoreId" } },
                [
                  _c(
                    "a-select",
                    {
                      attrs: { placeholder: "请选择" },
                      on: { select: _vm.getAveragePrice },
                      model: {
                        value: _vm.goods.scoreId,
                        callback: function($$v) {
                          _vm.$set(_vm.goods, "scoreId", $$v)
                        },
                        expression: "goods.scoreId"
                      }
                    },
                    _vm._l(_vm.infoByCode.scoreList, function(item) {
                      return _c(
                        "a-select-option",
                        { key: item.id, attrs: { value: item.id } },
                        [_vm._v(_vm._s(item.scoreName))]
                      )
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "a-form-model-item",
                { attrs: { label: "金额", prop: "price" } },
                [
                  _c("a-input", {
                    attrs: { placeholder: "金额" },
                    model: {
                      value: _vm.goods.price,
                      callback: function($$v) {
                        _vm.$set(_vm.goods, "price", $$v)
                      },
                      expression: "goods.price"
                    }
                  }),
                  _vm.showLog
                    ? _c("span", { staticStyle: { color: "red" } }, [
                        _vm._v(
                          "警告: 偏离前7天平均价" +
                            _vm._s(_vm.showlogTip) +
                            "% （"
                        ),
                        _c(
                          "label",
                          {
                            style:
                              _vm.showTipInfo == true
                                ? "color:red;"
                                : "color:green"
                          },
                          [
                            _vm._v(
                              _vm._s(_vm.showTipInfo == true ? "增长" : "降低")
                            )
                          ]
                        ),
                        _vm._v("）")
                      ])
                    : _vm._e()
                ],
                1
              ),
              _c(
                "a-form-model-item",
                { attrs: { label: "成交时间", prop: "tradeTime" } },
                [
                  _c("a-date-picker", {
                    attrs: { placeholder: "成交时间" },
                    on: {
                      change: function($event) {
                        return _vm.changeTime($event, 1)
                      }
                    },
                    model: {
                      value: _vm.goods.tradeTime,
                      callback: function($$v) {
                        _vm.$set(_vm.goods, "tradeTime", $$v)
                      },
                      expression: "goods.tradeTime"
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "a-modal",
        {
          attrs: { visible: _vm.previewVisible, footer: null },
          on: { cancel: _vm.handleCancel }
        },
        [
          _c("img", {
            staticStyle: { width: "100%" },
            attrs: { alt: "example", src: _vm.previewImage }
          })
        ]
      ),
      _vm.editDollatDetail
        ? _c("EditDollat", {
            ref: "editDollat",
            attrs: { detail: _vm.editDollatDetail },
            on: { close: _vm.closeEdit, success: _vm.editSuccess }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }