<template>
  <div>
    <a-modal
      v-drag-modal
      :width="1000"
      v-model="show"
      title="编辑"
      ok-text="确认"
      cancel-text="取消"
      @cancel="resetForms"
      @ok="hideModal"
    >
      <a-form-model
        v-if="goods"
        ref="ruleForm"
        :model="goods"
        :rules="rules"
        v-bind="layout"
      >
        <a-form-model-item label="商品名称">
          <a-input v-model="goods.title" placeholder="商品名称" />
        </a-form-model-item>
        <a-form-model-item label="评级公司" prop="goodsType">
          <a-radio-group
            @change="setRadioGroup"
            name="radioGroup"
            v-model="goods.goodsType"
          >
            <a-radio :value="1">PCGS</a-radio>
            <a-radio :value="2">公博</a-radio>
          </a-radio-group>
          <div v-if="goods.goodsType == 1">
            <div style="display: flex; align-items: center">
              <span>编码: </span>
              <a-input
                @blur="changeCode"
                style="flex: 1"
                v-model="goods.pcgsCode"
                size="small"
              ></a-input>
            </div>
            <div>
              <a-radio-group
                v-if="showCheckRadio"
                name="radioGroup"
                @change="changeRadio"
              >
                <a-radio
                  v-for="items of checkVersionsList"
                  :key="items.kindVersionId"
                  :value="items.kindVersionId"
                  >{{ items.kindVersionName }}</a-radio
                >
              </a-radio-group>
            </div>

            <span v-if="goods.versionName"
              >版别名称:{{ goods.versionName }}</span
            >
            <div style="display: flex; align-items: center">
              <span>编号: </span>
              <a-input
                style="flex: 1"
                v-model="goods.pcgsNumber"
                size="small"
              ></a-input>
            </div>
          </div>
          <div
            v-if="goods.goodsType == 2"
            style="display: flex; align-items: center"
          >
            <span style="white-space: nowrap">编号: </span>
            <a-input
              v-model="goods.pcgsNumber"
              size="small"
              placeholder="请输入公博盒子证书编号"
            ></a-input>
          </div>
        </a-form-model-item>
        <a-form-model-item label="评级分数" prop="scoreName">
          <a-input placeholder="评级分数" v-model="goods.scoreName" />
        </a-form-model-item>
        <a-form-model-item label="图片">
          <a-upload
            :key="imgKey"
            :action="ip + '/file/upload'"
            list-type="picture-card"
            :file-list="fileList"
            @preview="handlePreview($event, 2)"
            @change="handleChange"
          >
            <div>
              <a-icon type="plus" />
              <div class="ant-upload-text">上传图片</div>
            </div>
          </a-upload>
          <div>
            <a-button @click="previewImgs(fileList)">预览图片</a-button>
          </div>
        </a-form-model-item>
        <a-form-item label="封面图">
          <a-upload
            :key="imgKey"
            :action="ip + '/file/upload'"
            list-type="picture-card"
            :file-list="coverList"
            @preview="handlePreview"
            @change="handleChangeCoverList"
          >
            <div>
              <a-icon type="plus" />
              <div class="ant-upload-text">上传图片</div>
            </div>
          </a-upload>
        </a-form-item>

        <a-form-model-item label="金额" prop="price">
          <a-input placeholder="金额" v-model="goods.price" />
        </a-form-model-item>
        <a-form-model-item label="成交时间" prop="tradeTime">
          <a-date-picker
            placeholder="成交时间"
            @change="changeTime($event, 1)"
            v-model="goods.tradeTime"
          />
        </a-form-model-item>
      </a-form-model>
    </a-modal>
    <!-- <a-modal
      :visible="previewVisible"
      :footer="null"
      :mask="false"
      :maskStyle="{
        display: 'nonde'
      }"
      @cancel="previewVisible = false"
      :dialogStyle="{
        left: '150px'
      }"
    >
      <img alt="example" style="width: 100%" :src="previewImage" />
    </a-modal> -->
    <div class="preview-image" v-show="previewVisible">
      <div class="preview-image-close" @click="previewVisible = false">关闭</div>
      <img alt="example" :src="previewImage" />
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  props: ["detail"],
  data() {
    return {
      show: false,
      goods: "",
      previewVisible: false,
      previewImage: "",
      coverList: [],
      fileList: [],
      checkVersionsList: [],
      imgKey: "",
      layout: {
        labelCol: { span: 4 }, //标签占5份  共24份
        wrapperCol: { span: 20 },
      },
      rules: {
        goodsType: [
          { required: true, message: "please change", trigger: "change" },
        ],
      },
    };
  },
  computed: {
    ...mapState(["ip"]),

    showCheckRadio() {
      let inner;
      if (this.checkVersionsList.length > 1) {
        inner = true;
      } else {
        inner = false;
      }
      return inner;
    },
  },
  mounted() {
    addEventListener("paste", (params) => {
      this.untils.pasteImg(params, 1, (a, b, s) => {
        this.coverList.push({
          uid: this.coverList.length + 1,
          name: "image",
          status: "done",
          url: b,
        });
      });
    });
    this.setDetail();
  },
  methods: {
    setDetail() {
      this.goods = JSON.parse(
        JSON.stringify({
          ...this.detail,
          scoreName: this.detail.pcgsScore,
          goodsType: this.detail.companyType,
        })
      );
      const { images } = this.goods || { images: [] };
      if (images && images.length > 0) {
        this.fileList = images.map((items, i) => {
          const obj = {
            uid: i,
            name: "image",
            status: "done",
            url: items,
          };
          return obj;
        });
      }
      if (this.goods.coverUrlList) {
        this.coverList = this.goods.coverUrlList.map((items, i) => {
          const obj = {
            uid: i,
            name: "image",
            status: "done",
            url: items,
          };
          return obj;
        });
      }
    },
    changeCode(code) {
      const { value } = code.target;
    },
    setRadioGroup() {
      this.$set(this.goods, "companyType", this.goods.goodsType);
    },
    changeTime(time) {
      const { _d } = time;
      this.goods.tradeTime = new Date(_d)
        .toLocaleDateString()
        .replace(/\//g, "-");
    },
    handleChange({ fileList }) {
      fileList.map((row) => {
        try {
          if (row.response.data) {
            row.url = row.response.data;
          }
        } catch (e) {}
      });
      this.fileList = JSON.parse(JSON.stringify(fileList));
    },
    handleChangeCoverList({ fileList }) {
      fileList.map((row) => {
        try {
          if (row.response.data) {
            row.url = row.response.data;
          }
        } catch (e) {}
      });
      this.coverList = fileList;
    },
    previewImgs() {
      const images = this.fileList.map((row) => {
        return row.url;
      });
      if (images && images.length > 0) {
        this.$previewImg({
          list: images.reduce((pre, cur) => {
            const obj = { img_url: cur };
            pre = pre.concat(obj);
            return pre;
          }, []),

          baseImgField: "img_url",
          showMute: false,
        });
      }
    },
    handlePreview(file, type) {
      this.handlePreviewProxy(file);
    },
    async handlePreviewProxy(file) {
      if (!file.url && !file.preview) {
        file.preview = await this.getBase64(file.originFileObj);
      }
      this.previewImage = file.url || file.preview;
      this.previewVisible = true;
    },
    getBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      });
    },
    hideModal(type) {
      if (this.goods.companyType == 1) {
        if (!this.goods.pcgsCode) {
          return this.$message.error("请输入PCGS编码");
        }
        if (!this.goods.scoreName) {
          return this.$message.error("请输入评级分数");
        }
      }
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          this.submit(this.goods);
        } else {
          return false;
        }
      });
    },
    async submit(forms) {
      const pictures = this.fileList.map((row) => {
        return row.url;
      });
      const abs = this.coverList.map((row) => {
        return row.url;
      });
      forms.pictures = pictures.join(",");
      forms.coverUrl = abs.join(",");
      const post_url =
        "/dq_admin/coinSilverVersionGoodsSplider/editSpliderById";
      const sendObj = {
        spliderId: forms.id,
        title: forms.title,
        scoreName: forms.scoreName,
        tradeTime: forms.tradeTime,
        price: forms.price,
        pcgsCode: forms.pcgsCode,
        goodsNumber: forms.pcgsNumber,
        coverUrl: forms.coverUrl,
        pictures: forms.pictures,
        companyType: forms.companyType,
      };
      const res = await this.axios.post(post_url, sendObj);
      if (res.status != 200) return;
      this.$message.success(res.message);
      this.$emit("success");
    },
    resetForms() {
      this.$emit("close");
    },
  },
};
</script>

<style lang="scss" scoped>
.preview-image{
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-20%, -50%);
  z-index: 1001;
  padding: 32px 16px;
  background: #f2f2f2;
  border-radius: 8px;
  text-align: center;
  height: 80vh;
  img{
    height: 100%;
  }
  .preview-image-close{
    position: absolute;
    right: 0;
    top: 0;
    padding: 8px;
    cursor: pointer;
  }
}
</style>
