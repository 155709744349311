var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "a-modal",
        {
          directives: [{ name: "drag-modal", rawName: "v-drag-modal" }],
          attrs: {
            width: 1000,
            title: "编辑",
            "ok-text": "确认",
            "cancel-text": "取消"
          },
          on: { cancel: _vm.resetForms, ok: _vm.hideModal },
          model: {
            value: _vm.show,
            callback: function($$v) {
              _vm.show = $$v
            },
            expression: "show"
          }
        },
        [
          _vm.goods
            ? _c(
                "a-form-model",
                _vm._b(
                  {
                    ref: "ruleForm",
                    attrs: { model: _vm.goods, rules: _vm.rules }
                  },
                  "a-form-model",
                  _vm.layout,
                  false
                ),
                [
                  _c(
                    "a-form-model-item",
                    { attrs: { label: "商品名称" } },
                    [
                      _c("a-input", {
                        attrs: { placeholder: "商品名称" },
                        model: {
                          value: _vm.goods.title,
                          callback: function($$v) {
                            _vm.$set(_vm.goods, "title", $$v)
                          },
                          expression: "goods.title"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "a-form-model-item",
                    { attrs: { label: "评级公司", prop: "goodsType" } },
                    [
                      _c(
                        "a-radio-group",
                        {
                          attrs: { name: "radioGroup" },
                          on: { change: _vm.setRadioGroup },
                          model: {
                            value: _vm.goods.goodsType,
                            callback: function($$v) {
                              _vm.$set(_vm.goods, "goodsType", $$v)
                            },
                            expression: "goods.goodsType"
                          }
                        },
                        [
                          _c("a-radio", { attrs: { value: 1 } }, [
                            _vm._v("PCGS")
                          ]),
                          _c("a-radio", { attrs: { value: 2 } }, [
                            _vm._v("公博")
                          ])
                        ],
                        1
                      ),
                      _vm.goods.goodsType == 1
                        ? _c("div", [
                            _c(
                              "div",
                              {
                                staticStyle: {
                                  display: "flex",
                                  "align-items": "center"
                                }
                              },
                              [
                                _c("span", [_vm._v("编码: ")]),
                                _c("a-input", {
                                  staticStyle: { flex: "1" },
                                  attrs: { size: "small" },
                                  on: { blur: _vm.changeCode },
                                  model: {
                                    value: _vm.goods.pcgsCode,
                                    callback: function($$v) {
                                      _vm.$set(_vm.goods, "pcgsCode", $$v)
                                    },
                                    expression: "goods.pcgsCode"
                                  }
                                })
                              ],
                              1
                            ),
                            _c(
                              "div",
                              [
                                _vm.showCheckRadio
                                  ? _c(
                                      "a-radio-group",
                                      {
                                        attrs: { name: "radioGroup" },
                                        on: { change: _vm.changeRadio }
                                      },
                                      _vm._l(_vm.checkVersionsList, function(
                                        items
                                      ) {
                                        return _c(
                                          "a-radio",
                                          {
                                            key: items.kindVersionId,
                                            attrs: {
                                              value: items.kindVersionId
                                            }
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(items.kindVersionName)
                                            )
                                          ]
                                        )
                                      }),
                                      1
                                    )
                                  : _vm._e()
                              ],
                              1
                            ),
                            _vm.goods.versionName
                              ? _c("span", [
                                  _vm._v(
                                    "版别名称:" + _vm._s(_vm.goods.versionName)
                                  )
                                ])
                              : _vm._e(),
                            _c(
                              "div",
                              {
                                staticStyle: {
                                  display: "flex",
                                  "align-items": "center"
                                }
                              },
                              [
                                _c("span", [_vm._v("编号: ")]),
                                _c("a-input", {
                                  staticStyle: { flex: "1" },
                                  attrs: { size: "small" },
                                  model: {
                                    value: _vm.goods.pcgsNumber,
                                    callback: function($$v) {
                                      _vm.$set(_vm.goods, "pcgsNumber", $$v)
                                    },
                                    expression: "goods.pcgsNumber"
                                  }
                                })
                              ],
                              1
                            )
                          ])
                        : _vm._e(),
                      _vm.goods.goodsType == 2
                        ? _c(
                            "div",
                            {
                              staticStyle: {
                                display: "flex",
                                "align-items": "center"
                              }
                            },
                            [
                              _c(
                                "span",
                                { staticStyle: { "white-space": "nowrap" } },
                                [_vm._v("编号: ")]
                              ),
                              _c("a-input", {
                                attrs: {
                                  size: "small",
                                  placeholder: "请输入公博盒子证书编号"
                                },
                                model: {
                                  value: _vm.goods.pcgsNumber,
                                  callback: function($$v) {
                                    _vm.$set(_vm.goods, "pcgsNumber", $$v)
                                  },
                                  expression: "goods.pcgsNumber"
                                }
                              })
                            ],
                            1
                          )
                        : _vm._e()
                    ],
                    1
                  ),
                  _c(
                    "a-form-model-item",
                    { attrs: { label: "评级分数", prop: "scoreName" } },
                    [
                      _c("a-input", {
                        attrs: { placeholder: "评级分数" },
                        model: {
                          value: _vm.goods.scoreName,
                          callback: function($$v) {
                            _vm.$set(_vm.goods, "scoreName", $$v)
                          },
                          expression: "goods.scoreName"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "a-form-model-item",
                    { attrs: { label: "图片" } },
                    [
                      _c(
                        "a-upload",
                        {
                          key: _vm.imgKey,
                          attrs: {
                            action: _vm.ip + "/file/upload",
                            "list-type": "picture-card",
                            "file-list": _vm.fileList
                          },
                          on: {
                            preview: function($event) {
                              return _vm.handlePreview($event, 2)
                            },
                            change: _vm.handleChange
                          }
                        },
                        [
                          _c(
                            "div",
                            [
                              _c("a-icon", { attrs: { type: "plus" } }),
                              _c("div", { staticClass: "ant-upload-text" }, [
                                _vm._v("上传图片")
                              ])
                            ],
                            1
                          )
                        ]
                      ),
                      _c(
                        "div",
                        [
                          _c(
                            "a-button",
                            {
                              on: {
                                click: function($event) {
                                  return _vm.previewImgs(_vm.fileList)
                                }
                              }
                            },
                            [_vm._v("预览图片")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "a-form-item",
                    { attrs: { label: "封面图" } },
                    [
                      _c(
                        "a-upload",
                        {
                          key: _vm.imgKey,
                          attrs: {
                            action: _vm.ip + "/file/upload",
                            "list-type": "picture-card",
                            "file-list": _vm.coverList
                          },
                          on: {
                            preview: _vm.handlePreview,
                            change: _vm.handleChangeCoverList
                          }
                        },
                        [
                          _c(
                            "div",
                            [
                              _c("a-icon", { attrs: { type: "plus" } }),
                              _c("div", { staticClass: "ant-upload-text" }, [
                                _vm._v("上传图片")
                              ])
                            ],
                            1
                          )
                        ]
                      )
                    ],
                    1
                  ),
                  _c(
                    "a-form-model-item",
                    { attrs: { label: "金额", prop: "price" } },
                    [
                      _c("a-input", {
                        attrs: { placeholder: "金额" },
                        model: {
                          value: _vm.goods.price,
                          callback: function($$v) {
                            _vm.$set(_vm.goods, "price", $$v)
                          },
                          expression: "goods.price"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "a-form-model-item",
                    { attrs: { label: "成交时间", prop: "tradeTime" } },
                    [
                      _c("a-date-picker", {
                        attrs: { placeholder: "成交时间" },
                        on: {
                          change: function($event) {
                            return _vm.changeTime($event, 1)
                          }
                        },
                        model: {
                          value: _vm.goods.tradeTime,
                          callback: function($$v) {
                            _vm.$set(_vm.goods, "tradeTime", $$v)
                          },
                          expression: "goods.tradeTime"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e()
        ],
        1
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.previewVisible,
              expression: "previewVisible"
            }
          ],
          staticClass: "preview-image"
        },
        [
          _c(
            "div",
            {
              staticClass: "preview-image-close",
              on: {
                click: function($event) {
                  _vm.previewVisible = false
                }
              }
            },
            [_vm._v("关闭")]
          ),
          _c("img", { attrs: { alt: "example", src: _vm.previewImage } })
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }