import {
    angleList,
    clarityList,
    frontBackSideList,
    POST_DATA_JSON, rustList
} from "@/views/cmsPage/versionManage/duibanModelLabel/_data"
import {filteredParams} from "@/untils/otherEvent"

/** 列表点击判断提交的数据 */
export const judgePostModelLabel = (value, jsonKey, jsonStr, coinType) => {
    if (jsonKey !== 'coinType') {
        let tempJson = JSON.parse(JSON.stringify(POST_DATA_JSON[coinType]))
        if (jsonStr) {
            Object.assign(tempJson, JSON.parse(jsonStr))
        }
        if (tempJson[jsonKey] === value) {
            tempJson[jsonKey] = ''
        } else {
            tempJson[jsonKey] = value
        }
        return tempJson
    } else {
       return JSON.parse(JSON.stringify(POST_DATA_JSON[coinType]))
    }
}

/** 编辑弹窗计算必传参数 */
export const computedMustParamsByPopup = (jsonKey, jsonValue, mustParams) => {
    const tempMustParams = JSON.parse(JSON.stringify(mustParams))
    if (jsonKey === '正面面文') {
        tempMustParams.coinId = jsonValue
    }
    return filteredParams(tempMustParams)
}

/** 匹配name， 返回Value */
export const typeListReturnValue = (list, name) => {
    return list.find(item => item.name === name).value
}
/** 列表计算必传参数 */
export const computedMustParamsByList = (jsonKey, jsonValue, mustParams) => {
    const tempMustParams = JSON.parse(JSON.stringify(mustParams))
    if (jsonKey === '正反面') {
        tempMustParams.frontBackSide = typeListReturnValue(frontBackSideList, jsonValue)
    } else if (jsonKey === '清晰度') {
        tempMustParams.clarity = typeListReturnValue(clarityList, jsonValue)
    } else if (jsonKey === '角度') {
        tempMustParams.angle = typeListReturnValue(angleList, jsonValue)
    } else if (jsonKey === '锈迹') {
        tempMustParams.rust = typeListReturnValue(rustList, jsonValue)
    }
    return filteredParams(tempMustParams)
}

/** 列表计算必传参数 */
export const computedMustParamsByListV2 = (jsonKey, jsonData, mustParams) => {
    const tempMustParams = JSON.parse(JSON.stringify(mustParams))
    tempMustParams[jsonKey] = jsonData.value
    return filteredParams(tempMustParams)
}