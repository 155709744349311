<template>
  <div class="content">
    <div class="top">
      <!-- <div>
                <a-button>待录入数据</a-button>
                <a-button>自动录入待审核</a-button>
            </div> -->
      <div>
        <div class="item-btn">
          <a-input
            allowClear
            v-model="searchAllStr"
            size="small"
            style="width: 150px; margin: 5px"
            placeholder="全网搜索"
          ></a-input>
          <a-button type="primary" @click="searchAll" size="small" style="margin-left: 10px"
          >全网搜索</a-button
        >
        </div>

      </div>
      <div style="display: flex">
        <a-upload
          name="file"
          :multiple="true"
          :action="ip + '/file/upload'"
          @change="handleChanges"
          :showUploadList="false"
          :key="imgKey"
        >
          <a-button type="primary" style="margin-right: 20px"
            >上传PCGS图片录入</a-button
          >
        </a-upload>

        <a-button @click="openModal({ custom: 1 })" type="primary"
          >手动录入</a-button
        >
      </div>
    </div>

    <!-- <div class='bread-list'>
            <a-button @click="setKeywords(item)" ghost :type='item.goodsType == 1 ? "danger" : "warning"' :style="index == 0 ? '' : 'margin-left:10px'" v-for="(item,index) of list" :key="index">{{item.versionBetweenDay}}</a-button>
        </div> -->
    <div class="search-attr">
      <div>
        <a-input
          allowClear
          v-model="params.keyword"
          size="small"
          style="width: 150px; margin: 5px"
          placeholder="银元版别名称"
        ></a-input>
        <a-select
          allowClear
          size="small"
          style="width: 150px; margin: 5px"
          v-model="params.type"
          placeholder="数据源"
        >
          <a-select-option :value="1">HX</a-select-option>
          <a-select-option :value="3">WPT</a-select-option>
          <a-select-option :value="4">XZ</a-select-option>
          <a-select-option :value="10">SBP</a-select-option>
          <a-select-option :value="2">QBTT</a-select-option>
        </a-select>

        <a-select
          allowClear
          size="small"
          style="width: 150px; margin: 5px"
          v-model="params.companyType"
          placeholder="评级公司"
        >
          <a-select-option :value="1">PCGS</a-select-option>
          <a-select-option :value="2">公博</a-select-option>
          <a-select-option :value="0">无</a-select-option>
        </a-select>

        <a-select
          allowClear
          size="small"
          style="width: 150px; margin: 5px"
          v-model="params.coverUrlState"
          placeholder="是否有封面图"
        >
          <a-select-option :value="1">有</a-select-option>
          <a-select-option :value="0">无</a-select-option>
        </a-select>

        <a-select
          size="small"
          mode="multiple"
          style="margin: 5px; min-width: 150px"
          v-model="params.scoreKeyword"
          placeholder="选择评分(可多选)"
        >
          <a-select-option v-for="item of ranks" :key="item" :value="item">{{
            item
          }}</a-select-option>
        </a-select>
        <a-range-picker
          :placeholder="['开始时间', '结束时间']"
          size="small"
          style="margin: 5px"
          @change="onChange"
        />
        <a-button @click="search" size="small" style="margin-left: 10px"
          >搜索</a-button
        >
      </div>
    </div>
    <div class="table-content" style="padding-top: 20px">
      <a-table
        @change="changePage"
        :pagination="pagination"
        :loading="loading"
        :columns="columns"
        :data-source="data"
        :rowKey="(record) => record.id"
      >
        <div
          slot="Images"
          slot-scope="row"
          style="
            width: 100px;
            height: 100px;
            display: flex;
            justify-content: center;
            align-items: center;
          "
        >
          <img
            @click="previewImg(row)"
            style="max-width: 100px; max-height: 100px"
            :src="
              row.images != null
                ? row.images[0]
                : 'http://image.douquan.com/static/product-default.png'
            "
            alt=""
          />
        </div>
        <div slot="setup" slot-scope="row">
          <a-button
            type="primary"
            size="small"
            @click="editDollat(row)"
            style="margin-right: 5px"
            >编辑</a-button
          >
          <a-button
            type="primary"
            size="small"
            @click="updateImage(row)"
            style="margin-right: 5px"
            >更新封面</a-button
          >

          <a-button
            type="primary"
            size="small"
            @click="openModal(row)"
            style="margin-right: 5px"
            >录入</a-button
          >
          <a-popconfirm
            title="Are you sure delete this item?"
            ok-text="Yes"
            cancel-text="No"
            @confirm="remove(row.id)"
          >
            <a-button size="small" type="danger" style="margin-left: 5px"
              >删除</a-button
            >
          </a-popconfirm>
        </div>
      </a-table>
    </div>

    <a-modal
      v-drag-modal
      :width="1000"
      v-model="visible"
      title="录入"
      ok-text="确认"
      cancel-text="取消"
      @cancel="resetForms"
      @ok="hideModal"
    >
      <a-form-model
        ref="ruleForm"
        :model="goods"
        :rules="rules"
        v-bind="layout"
      >
        <a-form-model-item label="商品名称">
          <a-input v-model="goods.title" placeholder="商品名称" />
        </a-form-model-item>
        <a-form-model-item label="评级公司" prop="goodsType">
          <a-radio-group
            @change="setRadioGroup"
            name="radioGroup"
            v-model="goods.goodsType"
          >
            <a-radio :value="1">PCGS</a-radio>
            <a-radio :value="2">公博</a-radio>
          </a-radio-group>
          <div v-if="goods.goodsType == 1">
            <div style="display: flex; align-items: center">
              <span>编码: </span>
              <a-input
                @blur="changeCode"
                style="flex: 1"
                v-model="goods.pcgsCode"
                size="small"
              ></a-input>
            </div>
            <div>
              <a-radio-group
                v-if="showCheckRadio"
                name="radioGroup"
                @change="changeRadio"
              >
                <a-radio
                  v-for="items of checkVersionsList"
                  :key="items.kindVersionId"
                  :value="items.kindVersionId"
                  >{{ items.kindVersionName }}</a-radio
                >
              </a-radio-group>
            </div>

            <span v-if="goods.versionName"
              >版别名称:{{ goods.versionName }}</span
            >
            <div style="display: flex; align-items: center">
              <span>编号: </span>
              <a-input
                style="flex: 1"
                v-model="goods.pcgsNumber"
                size="small"
              ></a-input>
            </div>
          </div>
          <div v-if="goods.goodsType == 2">
            <a-select
              @change="changeKindIdForverSions"
              size="small"
              v-model="goods.kindId"
              placeholder="请选择银元一级大币种"
            >
              <a-select-option
                v-for="items of bigList"
                :key="items.kindId"
                :value="items.kindId"
              >
                {{ items.kindName }}
              </a-select-option>
            </a-select>
            <a-select
              @select="getInfoByCode($event, 2)"
              allowClear
              @search="getVersitionList"
              showSearch
              :filter-option="untils.filterOption"
              size="small"
              v-model="goods.versionId"
              placeholder="请选择银元二级版别"
            >
              <a-select-option
                v-for="items of smallList"
                :key="items.versionId"
                :value="items.versionId"
              >
                {{ items.versionName }}
              </a-select-option>
            </a-select>
            <a-input
              @change="getInfoByCode($event, 2)"
              v-model="goods.pcgsNumber"
              size="small"
              placeholder="请输入公博盒子证书编号"
            ></a-input>
            <span v-if="infoByCode.dealState.inputStatus == 1"
              >提示:
              <label style="color: red">{{
                infoByCode.dealState.message
              }}</label></span
            >
          </div>
        </a-form-model-item>
        <a-form-model-item label="图片">
          <a-upload
            :key="imgKey"
            :action="ip + '/file/upload'"
            list-type="picture-card"
            :file-list="fileList"
            @preview="handlePreview($event, 2)"
            @change="handleChange"
          >
            <div>
              <a-icon type="plus" />
              <div class="ant-upload-text">上传图片</div>
            </div>
          </a-upload>
          <div>
            <a-button @click="previewImgs(fileList)">预览图片</a-button>
          </div>
        </a-form-model-item>
        <a-form-item label="封面图">
          <a-upload
            :key="imgKey"
            :action="ip + '/file/upload'"
            list-type="picture-card"
            :file-list="coverList"
            @preview="handlePreview"
            @change="handleChangeCoverList"
          >
            <div>
              <a-icon type="plus" />
              <div class="ant-upload-text">上传图片</div>
            </div>
          </a-upload>
        </a-form-item>
        <a-form-model-item label="白币" prop="whiteCoinStatus">
          <a-select
            size="small"
            v-model="goods.whiteCoinStatus"
            placeholder="请选择"
          >
            <a-select-option :value="1">是</a-select-option>
            <a-select-option :value="0">否</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="评级分数" prop="scoreId">
          <a-select
            @select="getAveragePrice"
            v-model="goods.scoreId"
            placeholder="请选择"
          >
            <a-select-option
              v-for="item in infoByCode.scoreList"
              :key="item.id"
              :value="item.id"
              >{{ item.scoreName }}</a-select-option
            >
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="金额" prop="price">
          <a-input placeholder="金额" v-model="goods.price" />
          <span v-if="showLog" style="color: red"
            >警告: 偏离前7天平均价{{ showlogTip }}% （<label
              :style="showTipInfo == true ? 'color:red;' : 'color:green'"
              >{{ showTipInfo == true ? "增长" : "降低" }}</label
            >）</span
          >
        </a-form-model-item>
        <a-form-model-item label="成交时间" prop="tradeTime">
          <a-date-picker
            placeholder="成交时间"
            @change="changeTime($event, 1)"
            v-model="goods.tradeTime"
          />
        </a-form-model-item>
      </a-form-model>
    </a-modal>

    <a-modal :visible="previewVisible" :footer="null" @cancel="handleCancel">
      <img alt="example" style="width: 100%" :src="previewImage" />
    </a-modal>
    <!-- 编辑操作 -->
    <EditDollat
      ref="editDollat"
      v-if="editDollatDetail"
      :detail="editDollatDetail"
      @close="closeEdit"
      @success="editSuccess"
    ></EditDollat>
  </div>
</template>

<script>
import { mapState } from "vuex";
import EditDollat from "@/views/cmsPage/currencyMannage/dollat/EditDollat.vue";
function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}
export default {
  data() {
    return {
      searchAllStr: "",
      columns: [
        { title: "ID", dataIndex: "id" },
        { title: "商品名称", dataIndex: "title" },
        { title: "编号", dataIndex: "pcgsNumber" },
        {
          title: "评级公司",
          customRender: (text, row, index) => {
            switch (row.companyType) {
              case 0:
                return "无";
              case 1:
                return "PCSG";
              case 2:
                return "公博";
              default:
                break;
            }
          },
        },
        { title: "价格", dataIndex: "price" },
        { title: "分数", dataIndex: "pcgsScore" },
        {
          title: "封面",
          customRender: (text, row, index) => {
            if (row.coverUrl) {
              return <span style="color: green">有</span>;
            } else {
              return <span style="color: red">无</span>;
            }
          },
        },
        { title: "图片", scopedSlots: { customRender: "Images" } },
        {
          title: "数据源",
          customRender: (text, row, index) => {
            return (
              <span>
                {row.type == 1
                  ? "HX"
                  : row.type == 2
                  ? "QBTT"
                  : row.type == 3
                  ? "WPT"
                  : row.type == 10
                  ? "SBP"
                  : "XZ"}
              </span>
            );
          },
        },
        { title: "成交时间", dataIndex: "tradeTime" },
        {
          title: "操作",
          align: "center",
          scopedSlots: { customRender: "setup" },
        },
      ],
      list: [{ title: "华夏", type: 1 }],
      data: [],
      previewImage: "",
      layout: {
        labelCol: { span: 4 }, //标签占5份  共24份
        wrapperCol: { span: 20 },
      },
      forms: {},
      previewVisible: false,
      rules: {
        goodsType: [
          { required: true, message: "please change", trigger: "change" },
        ],
        goodsPrice: [
          { required: true, message: "please enter ", trigger: "blur" },
        ],
        scoreId: [
          { required: true, message: "please enter", trigger: "change" },
        ],
        tradeTime: [
          { required: true, message: "please enter", trigger: "change" },
        ],
        whiteCoinStatus: [
          { required: true, message: "please enter", trigger: "change" },
        ],
      },
      visible: false,
      params: { pageSize: 10, pageNum: 1, keyword: "" },
      pagination: {
        total: 0,
        current: 1,
        showTotal: (total) => `共${total}条`,
      },
      loading: false,
      imgKey: "",
      planType: 1,
      infoByCode: {
        dealState: {
          inputStatus: 0,
        },
        kindVersion: {
          kindVersionName: "",
        },
      },
      goods: {
        goodsType: 1,
      },
      bigList: [],
      smallList: [],
      showPriceTip: 0,
      fileList: [],
      ranks: [
        "30",
        "35",
        "40",
        "45",
        "50",
        "53",
        "55",
        "58",
        "60",
        "61",
        "62",
        "63",
        "64",
        "65",
        "66",
        "67",
        "70",
        "VF",
        "XF",
        "AU",
        "UNC",
        "MS",
        "SP",
      ],
      coverList: [],
      isSetCovers: false,
      checkVersionsList: [],
      editDollatDetail: "",
    };
  },
  computed: {
    ...mapState(["ip"]),
    showLog() {
      const ori = this.showPriceTip * 1 || 0;
      if (ori == 0) {
        return false;
      }
      const { price } = this.goods;
      const nowVal = price * 1;
      const speed = ori * 0.1;
      const _max = ori + speed;
      const _min = ori - speed;
      if (nowVal > _max || nowVal < _min) {
        return true;
      } else {
        return false;
      }
    },
    showCheckRadio() {
      let inner;
      if (this.checkVersionsList.length > 1) {
        inner = true;
      } else {
        inner = false;
      }
      return inner;
    },
    showlogTip() {
      const { price } = this.goods;
      if (this.showPriceTip == 0) {
        return 0;
      }
      const inner = Math.abs(
        (Math.abs(price / this.showPriceTip) - 1) * 100
      ).toFixed(1);
      return inner;
    },
    showTipInfo() {
      const { price } = this.goods;
      if (this.showPriceTip - price > 0) {
        return false;
      } else {
        return true;
      }
    },
  },
  watch: {
    visible() {
      this.imgKey = this.visible ? "" : Math.random();
    },
  },
  components: {
    EditDollat,
  },
  created() {
    this.getData(this.params);
    this.getTopList();
    this.getSelectList();
  },
  mounted() {
    addEventListener("paste", (params) => {
      this.untils.pasteImg(params, 1, (a, b, s) => {
        this.coverList.push({
          uid: this.coverList.length + 1,
          name: "image",
          status: "done",
          url: b,
        });
        // if(this.coverList&&this.coverList.length>0){
        //     // this.cove rList[0]['url'] = b;

        // }
      });
    });
  },
  methods: {
    // 全网搜索
    async searchAll(){
      if (!this.searchAllStr) {
        return this.$message.error("请输入关键字")
      }
      this.$loading.show();
      const res = await this.axios(
        "/dq_admin/coinSilverVersionGoodsSplider/globalNetworkSearch",
        {
          params: {
            keyword: this.searchAllStr,
          },
        }
      );
      this.$loading.hide();
      if (res.status != 200) {
        this.$message.error(res.message)
      } else {
        this.$message.success(res.message)
      }
    },
    // 更新封面
    async updateImage(row) {
      this.$loading.show();
      const res = await this.axios(
        "/dq_admin/coinSilverVersionGoodsSplider/editCover",
        {
          params: {
            id: row.id,
          },
        }
      );
      this.$loading.hide();

      if (res.status != 200) return this.$message.error(res.message);
      this.$message.success(res.message);
      this.getData(this.params);
    },
    editSuccess() {
      this.editDollatDetail = "";
      this.$refs["editDollat"].show = false;
      this.getData(this.params);
    },
    // 编辑
    editDollat(row) {
      this.editDollatDetail = JSON.parse(JSON.stringify(row));
      this.$nextTick(() => {
        this.$refs["editDollat"].show = true;
      });
    },
    // 关闭编辑
    closeEdit() {
      this.editDollatDetail = "";
    },
    async getSelectList() {
      const res = await this.axios(
        "/dq_admin/coinSilverVersionGoods/getSliverKind"
      );
      if (res.status == 200) {
        const { data } = res;
        this.bigList = data;
      }
    },
    
    openModal(row) {
      this.visible = true;
      this.forms = JSON.parse(JSON.stringify(row));
      let {
        title,
        tradeTime,
        price,
        pcgsCode,
        pcgsNumber,
        id,
        companyType,
        coverUrlList,
        coverUrl,
        scoreId,
      } = this.forms;

      if (row.custom == 1) {
        this.goods = {
          title: "",
          tradeTime: "",
          price: "",
          pcgsCode: "",
          pcgsNumber: "",
          goodsType: 1,
          scoreId: "",
        };
      } else {
        const { images } = row || { images: [] };
        if (images && images.length > 0) {
          this.fileList = images.map((items, i) => {
            const obj = {
              uid: i,
              name: "image",
              status: "done",
              url: items,
            };
            return obj;
          });
        }
        if (coverUrlList && coverUrlList.length > 0) {
          this.coverList = coverUrlList.map((items, i) => {
            const obj = {
              uid: i,
              name: "image",
              status: "done",
              url: items,
            };
            return obj;
          });
        }
        const len = this.coverList.length;
        if (len > 0) {
          this.isSetCovers = true;
        }
        this.goods = {
          title,
          tradeTime,
          price: price.replaceAll(",", ""),
          pcgsCode,
          pcgsNumber,
          goodsType: companyType,
          id,
          coverUrl,
          scoreId,
        };

        if (companyType == 1) {
          this.getInfoByCode(pcgsCode);
        }
      }
    },
    hideModal(type) {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          this.submit(this.goods);
        } else {
          return false;
        }
      });
    },
    submit(forms) {
      const images = this.fileList.map((row) => {
        return row.url;
      });
      const abs = this.coverList.map((row) => {
        return row.url;
      });
      forms.images = images.join(",");
      forms.coverUrl = abs.join(",");

      const post_url =
        "/dq_admin/coinSilverVersionGoods/inputSilverVersionGoods";
      this.axios.post(post_url, forms).then((res) => {
        if (res.status == "200") {
          this.$message.success(res.message);
          this.fileList = [];
          this.coverList = [];
          this.checkVersionsList = [];
          this.visible = false;
          this.goods = {};
          this.isSetCovers = false;
          this.showPriceTip = 0;
          this.infoByCode = {
            dealState: {
              inputStatus: 0,
            },
          };
          this.getData(this.params);
        }
      });
    },
    async getData(params) {
      const searchParams = JSON.parse(JSON.stringify(params));
      try {
        searchParams.scoreKeyword = searchParams.scoreKeyword.join(",");
      } catch (e) {}

      this.loading = true;
      const res = await this.axios(
        "/dq_admin/coinSilverVersionGoodsSplider/list",
        { params: searchParams }
      );
      this.loading = false;
      const { records, total } = res.data;
      this.data = records;
      this.pagination.total = total;
    },
    search() {
      this.pagination.current = 1;
      this.params.pageNum = 1;

      this.getData(this.params);
    },
    async remove(params) {
      const res = await this.axios(
        "/dq_admin/coinSilverVersionGoodsSplider/delCoinSilverVersionGoodsSpliderById",
        { params: { spliderId: params } }
      );
      if (res.status == 200) {
        this.$message.success(res.message);
        this.getData(this.params);
      }
    },
    resetForms() {
      this.forms = {};
      this.planType = 1;
      this.$refs.ruleForm.resetFields();
      this.fileList = [];
      this.coverList = [];
      this.isSetCovers = false;
      this.checkVersionsList = [];
      this.showPriceTip = 0;
      this.infoByCode = {
        dealState: {
          inputStatus: 0,
        },
        kindVersion: {
          kindVersionName: "",
        },
      };
      this.goods = {};
    },
    changePage(page) {
      const { current, pageSize } = page;
      this.pagination.current = current;
      this.params.pageNum = current;
      this.params.pageSize = pageSize;
      this.getData(this.params);
    },
    async getTopList() {
      const result = await this.axios(
        "/dq_admin/coinSilverVersionGoods/getSilverVersionGoodsByLongTradeTime"
      );
      if (result.status == 200) {
        this.list = result.data;
      }
    },
    async getInfoByCode(code, type = 1) {
      if (type == 1 && (code == null || code == "")) {
        return false;
      }
      // if(type == 2&&(this.goods.pcgsNumber==''||this.goods.pcgsNumber==null)){
      //     return false;
      // }
      let params = {};
      if (type == 2) {
        params = {
          versionId: this.goods.versionId,
          gbNumber: this.goods.pcgsNumber,
          tradeTime: this.goods.tradeTime,
        };
      } else if (type == 3) {
        params = {
          versionId: this.goods.versionId,
          tradeTime: this.goods.tradeTime,
        };
      } else {
        params = { pcgsCode: code, tradeTime: this.goods.tradeTime };
      }

      const res = await this.axios(
        "/dq_admin/coinSilverVersionGoods/getKindVersionAndScoreByPcgsCodeOrVersionId",
        { params: params }
      );
      if (res.status == 200) {
        this.infoByCode = res.data;
        this.goods.kindId = this.infoByCode.kind.kindId;
        try {
          let len = this.infoByCode.kindVersion.length || 0;
          if (len > 1) {
            this.checkVersionsList = this.infoByCode.kindVersion;
          } else {
            this.goods.versionId = this.infoByCode.kindVersion[0].kindVersionId;
            this.goods.versionName =
              this.infoByCode.kindVersion[0].kindVersionName;
          }
        } catch (err) {}
        try {
          this.showPriceTip =
            this.infoByCode.averagePrice.lastWeekAveragePrice * 1;
        } catch (e) {
          this.showPriceTip = 0;
        }
      } else {
        this.showPriceTip = 0;
      }
    },
    async getInfoByImage(url) {
      this.$loading.show();
      const res = await this.axios(
        "/dq_admin/coinSilverVersionGoods/ocrPcgsImageInfo",
        { params: { imgSrc: url } }
      );
      this.$loading.hide();
      if (res.status == 200) {
        this.openModal({
          ...res.data,
          custom: 2,
          title: "",
          tradeTime: "",
          price: "0",
          images: [url],
        });
      }
    },
    handleChanges(result) {
      const { fileList } = result;
      try {
        if (fileList[0].response.status == 200) {
          const { data } = fileList[0].response;
          this.imgKey = this.visible ? "" : Math.random();
          setTimeout(() => {
            this.getInfoByImage(data);
          }, 500);
        }
      } catch (e) {}
    },
    changeRate(e, type) {
      try {
        this.goods.goodsType = this.planType;
        this.$set(this.goods, "goodsType", this.planType);
      } catch (e) {
        this.goods.goodsType = e;
      }
    },
    changeTime(time) {
      const { _d } = time;
      this.goods.tradeTime = new Date(_d)
        .toLocaleDateString()
        .replace(/\//g, "-");
      this.getAveragePrice(this.goods.scoreId);
    },
    changeCode(code) {
      const { value } = code.target;
      this.getInfoByCode(value);
    },
    handleCancel() {
      this.previewVisible = false;
    },
    handlePreview(file, type) {
      if (type == 2) {
        if (this.isSetCovers == false) {
          this.$set(this.coverList, 0, file);
          this.coverList[0] = file;
        } else {
          this.handlePreviewProxy(file);
        }
      } else {
        this.handlePreviewProxy(file);
      }
    },
    async handlePreviewProxy(file) {
      if (!file.url && !file.preview) {
        file.preview = await getBase64(file.originFileObj);
      }
      this.previewImage = file.url || file.preview;
      this.previewVisible = true;
    },
    handleChange({ fileList }) {
      fileList.map((row) => {
        try {
          if (row.response.data) {
            row.url = row.response.data;
          }
        } catch (e) {}
      });
      this.fileList = fileList;
    },
    setKeywords(row) {
      this.params.keyword = row.versionName;
      this.search();
    },
    async getAveragePrice(id) {
      const res = await this.axios(
        "/dq_admin/coinSilverVersionGoods/getLastWeekAveragePrice",
        {
          params: {
            versionId: this.goods.versionId,
            goodsType: this.goods.goodsType,
            scoreId: id,
            tradeTime: this.goods.tradeTime,
          },
        }
      );
      if (res.status == 200) {
        const { lastWeekAveragePrice } = res.data;
        this.showPriceTip = lastWeekAveragePrice;
      }
    },
    setRadioGroup() {
      if (this.goods.scoreId) {
        this.getAveragePrice(this.goods.scoreId);
      }
    },
    async getVersitionList(str) {
      const res = await this.axios(
        "/dq_admin/coinSilverVersionGoods/getSliverVersion",
        {
          params: { keyword: str, kindId: this.goods.kindId },
        }
      );
      if (res.status == 200) {
        const { data } = res;
        this.smallList = data;
      }
    },
    changeKindIdForverSions(e) {
      this.$set(this.goods, "versionId", null);
      this.getVersitionList("");
    },
    previewImg(row) {
      if (row.images && row.images.length > 0) {
        this.$previewImg({
          list: row.images.reduce((pre, cur) => {
            const obj = { img_url: cur };
            pre = pre.concat(obj);
            return pre;
          }, []),

          baseImgField: "img_url",
          showMute: false,
        });
      }
    },
    previewImgs() {
      const images = this.fileList.map((row) => {
        return row.url;
      });
      if (images && images.length > 0) {
        this.$previewImg({
          list: images.reduce((pre, cur) => {
            const obj = { img_url: cur };
            pre = pre.concat(obj);
            return pre;
          }, []),

          baseImgField: "img_url",
          showMute: false,
        });
      }
    },
    onChange(array) {
      if (array && array.length > 0) {
        array.forEach((ele, index) => {
          if (index == 0) {
            this.params.startTime = ele._d
              .toLocaleDateString()
              .replace(/\//g, "-");
          } else {
            this.params.endTime = ele._d
              .toLocaleDateString()
              .replace(/\//g, "-");
          }
        });
      } else {
        this.params.startTime = "";
        this.params.endTime = "";
      }
    },
    handlePreviewCoverList() {},
    handleChangeCoverList({ fileList }) {
      fileList.map((row) => {
        try {
          if (row.response.data) {
            row.url = row.response.data;
          }
        } catch (e) {}
      });
      this.coverList = fileList;
    },
    parsetCallback(type, data, row) {
      if (this.coverList && this.coverList.length > 0) {
        this.coverList[0]["url"] = data;
      }
    },
    changeRadio(e) {
      const { value } = e.target;
      this.goods.versionId = value;
      this.goods.scoreId = "";
      this.getInfoByCode(value, 3);
      this.checkVersionsList.forEach((row) => {
        if (row.kindVersionId == value) {
          this.goods.versionName = row.kindVersionName;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.search-attr {
  display: flex;
  justify-content: space-between;
}
.content {
  display: flex;
  flex-direction: column;
  .table-content {
    flex: 1;
    overflow-y: auto;
  }
  .top {
    display: flex;
    justify-content: space-between;
    padding-bottom: 10px;
  }
  .bread-list {
    overflow: hidden;
    padding-bottom: 20px;
    padding-top: 10px;
  }
}
</style>